import { Contract } from '@ethersproject/contracts'
import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import useParticle from 'hooks/useParticle'

import abi from '../../../abis/phygital-items.json'
// import useActiveWeb3React from '../../../hooks/useActiveWeb3React'
import { ClaimNFTByNFCCodeResponse } from '../models/ClaimNFTByNFCCodeResponse'

export const useClaimNFT = (options?: UseMutationOptions<any, any, ClaimNFTByNFCCodeResponse>) => {
  // const { library } = useActiveWeb3React()
  const { provider } = useParticle()

  return useMutation<any, any, ClaimNFTByNFCCodeResponse>(async ({ tokenInformation, signature }) => {
    if (!provider) {
      throw new Error('NOT_CONNECTED_TO_METAMASK')
    }

    const contract = new Contract(tokenInformation.collectionAddress, abi, provider.getSigner())

    const tx = await contract.claimNft(tokenInformation.tokenId, signature)

    await tx.wait()

    return tx
  }, options)
}
