import { Contract } from '@ethersproject/contracts'
import Avatar from 'assets/images/avatar.svg'
import Fallback from 'assets/images/fallback.svg'
import IconArrowRight from 'assets/images/svg/icon-arrow-right.svg'
import axios from 'axios'
import IconPortal from 'components/IconPortal'
import Loader from 'components/Loader'
import { TabCs, TabListCs } from 'components/Tabs'
import { useApi } from 'hooks/useApi'
import useDocumentTitle from 'hooks/useDocumentTitle'
import useParticle from 'hooks/useParticle'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Heart } from 'react-feather'
import { Link as LinkRouter, useHistory, useParams } from 'react-router-dom'
import { TabPanel, Tabs } from 'react-tabs'
import { Flex } from 'rebass'
import { useGetNftDetailQuery } from 'state/nfts/slice'
import styled from 'styled-components/macro'
import { isVideoLink } from 'utils/helper'

import IconExpandFull from '../../assets/images/svg/icon-expand-full.svg'
import BoxUserCard from '../../components/BoxUserCard/BoxUserCard'
import { ButtonOutline, ButtonPrimary, ButtonSecondary } from '../../components/Button'
import ModalCodeAuthentication from '../../components/Modal/ModalCodeAuthentication'
// import useActiveWeb3React from '../../hooks/useActiveWeb3React'
import { RedeemModal } from '../../modules/Assets/components/RedeemModal'
import { updateLike } from '../../services'
import {
  useFullscreenNFTItemToggle,
  useModalAuthenticationToggle,
  useModalOpen,
  useModalRedeemToggle,
} from '../../state/application/hooks'
import { ApplicationModal } from '../../state/application/reducer'
import { SectionContentWrapper } from '../../theme'
import DetailTab from './Details'
import ListMoreCollection from './ListMoreCollection/ListMoreCollection'
import ModalFullscreenNFT from './ModalFullScreenNFT'

export type NftParams = {
  id: string
  collectionAddress: string
}

const NFTDetail = () => {
  const { collectionAddress, id } = useParams<NftParams>()
  // const { account, library } = useActiveWeb3React()
  const { account, provider } = useParticle()

  const {
    data: nftData,
    refetch,
    isLoading,
  } = useGetNftDetailQuery({
    id,
    addressCollection: collectionAddress,
    account: account || null,
  })
  const [nftDataDetail, setNftDataDetail] = useState<any>()
  const [successTime, setSuccessTime] = useState<boolean>(false)

  const history = useHistory()
  const toggleFullscreen = useFullscreenNFTItemToggle()
  const fullscreenModalOpen = useModalOpen(ApplicationModal.FULLSCREEN_ITEM_NFT)
  const toggleRedeem = useModalRedeemToggle()
  const redeemModalOpen = useModalOpen(ApplicationModal.REDEEM_POPUP)
  const toggleAuthentication = useModalAuthenticationToggle()
  const authenticationModalOpen = useModalOpen(ApplicationModal.AUTHENTICATION_POPUP)

  const [userProfile, setUserProfile] = useState<any | null>(null)

  const { post } = useApi()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])

  useEffect(() => {
    setNftDataDetail(nftData)
    if (account)
      axios
        .get(process.env.REACT_APP_SERVER_URL + `users/detail/${account}/${account}`)
        .then((res: any) => {
          const data = res.data
          setUserProfile(data)
        })
        .catch((err) => {
          console.log('error', err)
        })
  }, [nftData, account, id, collectionAddress])

  const handleClickLike = (id: string) => {
    if (!account) {
      return
    }

    ;(async () => {
      const response = await updateLike(id, collectionAddress, post)
      if (response?.status === 200) {
        refetch()
      }
    })()
  }

  const now = Date.now()
  const windowMsg = () => {
    if (nftData?.status === 0) {
      if (
        now > nftData?.collection?.redemptionWindow?.timeFrom * 1000 &&
        now < nftData?.collection?.redemptionWindow?.timeTo * 1000
      ) {
        return 'This NFT is redeemable and the redemption window is open.'
      }
      if (now < nftData?.collection?.redemptionWindow?.timeFrom * 1000) {
        return 'This Item is redeemable and the redemption window is opening soon.'
      }
      if (now > nftData?.collection?.redemptionWindow?.timeTo * 1000) {
        return 'The redemption window for this item is closed.'
      }
      return 'This item is redeemable, but the redemption window is not opened yet!'
    } else if (nftData?.status === 1) {
      return ' <p>This NFT is under manufacturing.</p><p>The physical item will be delivered to you soon.</p>'
    }
    return ''
  }

  useDocumentTitle(`${nftDataDetail?.name ? nftDataDetail.name + ' NFT |' : ''}  Another-1 Launchpad`)

  const toggleConvert1155 = async () => {
    // const signer = library?.getSigner()
    const erc1155 = new Contract(
      process.env.REACT_APP_NTR1_ADDRESS ?? '',
      [
        'function balanceOf(address account, uint256 id) public view virtual override returns (uint256)',
        'function safeTransferFrom(address from, address to, uint256 id, uint256 amount, bytes memory data) public virtual override ',
      ],
      provider?.getSigner()
    )

    const converterAddress = process.env.REACT_APP_CONVERTER_ADDRESS

    const tx = await erc1155
      .safeTransferFrom(account, converterAddress, 0, 1, [])
      .catch((err: any) => console.log({ err }))

    await tx?.wait()
  }

  const goToUserPage = () => {
    history.push({
      pathname: `/profile`,
    })
  }

  return (
    <NFTDetailWrapper>
      <InfoNFT>
        <DivRow>
          <DivInfoLeft>
            <DivInfoImg>
              <DivSquare>
                <BoxImg>
                  <img src={nftDataDetail?.image || Fallback} alt={'NFT'} />
                </BoxImg>
                <DivActionHover className={'action-hover'}>
                  <BoxFavorite className={'cs-favorite'}>
                    <span onClick={() => handleClickLike(nftDataDetail?.tokenId)}>
                      <Heart
                        className={
                          'icon-heart ' +
                          (nftDataDetail?.collection?.assetLikes?.some((x: any) => x.userAddress == account)
                            ? 'active'
                            : '')
                        }
                      />
                    </span>
                    <span>{nftDataDetail?.numberLikes}</span>
                  </BoxFavorite>
                  <BoxExpandFull onClick={toggleFullscreen}>
                    <span>
                      <img src={IconExpandFull || Fallback} alt={'IconExpandFull'} />
                    </span>
                  </BoxExpandFull>
                </DivActionHover>
              </DivSquare>
            </DivInfoImg>
          </DivInfoLeft>
          <DivInfoRight>
            <DivAction>
              <BoxButton>
                <LinkRouter to={`/collection/${nftData?.collection?.slug}`} style={{ textDecoration: 'none' }}>
                  <CtaBack>
                    <IconPortal SrcImageIcon={IconArrowRight} widthIcon={'20px'} heightIcon={'20px'} />
                    <span>Back to collection</span>
                  </CtaBack>
                </LinkRouter>
              </BoxButton>
            </DivAction>
            <TitleWrapper>
              <UserCard
                onClick={() => {
                  history.push(`/collection/${nftDataDetail?.collection?.slug}`)
                }}
              >
                <AvatarUser>
                  <img src={nftDataDetail?.thumbnail ? nftDataDetail?.thumbnail : Fallback} alt={'imgUserCard'} />
                </AvatarUser>
                <Username>{nftDataDetail?.collection?.name}</Username>
              </UserCard>
              <HeadingName>{nftDataDetail?.name}</HeadingName>
              <Flex justifyContent="space-between">
                <ProfileBox>
                  <LinkRouter to={`/user/${nftDataDetail?.creator?.address}`}>
                    <BoxUserCard
                      img={nftDataDetail?.creator?.imageUrl || Avatar}
                      labelName={'Created by'}
                      name={nftDataDetail?.creator?.name}
                      address={nftDataDetail?.creatorAddress}
                    />
                  </LinkRouter>
                </ProfileBox>
                <ProfileBox>
                  <LinkRouter to={`/user/${nftDataDetail?.owner?.address}`}>
                    <BoxUserCard
                      img={nftDataDetail?.owner?.imageUrl || Avatar}
                      labelName={'Current owner'}
                      name={nftDataDetail?.owner?.name}
                      address={nftDataDetail?.ownerAddress}
                    />
                  </LinkRouter>
                </ProfileBox>
              </Flex>
            </TitleWrapper>
            {nftData?.owner?.address === account && (
              <BoxAvailable>
                {isLoading ? (
                  <LoadingScreen>
                    <Loader size="60px" />
                  </LoadingScreen>
                ) : (
                  (nftDataDetail?.collection.address !== process.env.REACT_APP_NTR1_ADDRESS && (
                    <>
                      <Div dangerouslySetInnerHTML={{ __html: windowMsg() }} />
                      {now > nftData?.collection?.redemptionWindow?.timeFrom * 1000 &&
                        now < nftData?.collection?.redemptionWindow?.timeTo * 1000 &&
                        (!nftData?.collection?.RedemptionPhysicalPair ||
                          nftData?.collection?.RedemptionPhysicalPair?.length === 0) &&
                        nftData?.status === 0 &&
                        userProfile && (
                          <CtaRedeem
                            onClick={userProfile.kycStatus === 'APPROVED' ? toggleRedeem : goToUserPage}
                            disabled={nftData ? !nftData?.collection?.redemptionWindow?.timeFrom : true}
                          >
                            {userProfile.kycStatus === 'APPROVED' ? 'Redeem now' : 'Go to user page to get KYC'}
                          </CtaRedeem>
                        )}

                      {nftData?.status === 1 && (
                        <CtaRedeem onClick={toggleAuthentication} disabled={!nftData?.nfcId}>
                          {nftData?.nfcId ? 'Authenticate' : 'Manufacturing'}
                        </CtaRedeem>
                      )}

                      {nftData?.status === 2 && <Span>This NFT has been redeemed</Span>}

                      {nftData?.collection?.redemptionWindow?.timeFrom && (
                        <div>
                          {nftData?.collection?.redemptionWindow?.redemptionPrice && (
                            <p className="redemption">
                              <span>Redemption price</span>
                              <span>{parseInt(nftData?.collection?.redemptionWindow?.redemptionPrice) / 1e6} USDT</span>
                            </p>
                          )}
                          <p className="redemption">
                            <span>Redemption starts </span>
                            <span>
                              {moment(parseInt(nftData?.collection?.redemptionWindow?.timeFrom) * 1000).format(
                                'HH:mm, MMM DD YYYY'
                              )}
                            </span>
                          </p>
                          <p className="redemption">
                            <span>Redemption finishes </span>
                            <span>
                              {moment(parseInt(nftData.collection?.redemptionWindow?.timeTo) * 1000).format(
                                'HH:mm, MMM DD YYYY'
                              )}
                            </span>
                          </p>
                        </div>
                      )}
                    </>
                  )) || <CtaRedeem onClick={toggleConvert1155}>Convert to ERC721</CtaRedeem>
                )}
              </BoxAvailable>
            )}

            <TabWrapper>
              <Tabs>
                <TabListCs>
                  <TabCs>
                    <TabName>Details</TabName>
                  </TabCs>
                </TabListCs>
                <TabPanel>
                  <Details>{nftDataDetail && <DetailTab nftData={nftDataDetail} />}</Details>
                </TabPanel>
              </Tabs>
            </TabWrapper>
          </DivInfoRight>
        </DivRow>
      </InfoNFT>
      <MoreCollection>
        <h1>More from this collection</h1>
        <UserCard
          onClick={() => {
            history.push(`/collection/${nftDataDetail.collection.slug}`)
          }}
        >
          <AvatarUser>
            {isVideoLink(nftDataDetail?.collection?.media?.image) ? (
              <CustomVideo
                width={'100%'}
                height={'100%'}
                src={nftDataDetail?.collection?.media.image}
                controls={false}
                muted={true}
                playsInline={true}
                autoPlay={true}
                loop={true}
              />
            ) : (
              <img src={nftDataDetail?.collection?.media?.image || Fallback} alt={'imgUserCard'} />
            )}
          </AvatarUser>
          <Username>{nftDataDetail?.collection?.name}</Username>
        </UserCard>

        <ListMoreCollection collectionAddress={collectionAddress} userAddress={account} />
      </MoreCollection>
      <ModalFullscreenNFT
        isOpen={fullscreenModalOpen}
        onDismiss={toggleFullscreen}
        name={'modal_nft_fullscreen'}
        srcImg={nftDataDetail?.image || Fallback}
        numbersLike={nftDataDetail?.collection?.assetLikes?.length ?? 0}
        onClickLike={() => handleClickLike(nftDataDetail?.tokenId)}
        isLiked={nftDataDetail?.collection?.assetLikes?.some((x: any) => x.userAddress == account)}
      />
      <RedeemModal
        isOpen={redeemModalOpen}
        onDismiss={toggleRedeem}
        nftDetail={nftDataDetail}
        setSuccessTime={setSuccessTime}
      />
      <ModalCodeAuthentication
        assetDetails={{
          collectionAddress: nftDataDetail?.collection?.address,
          tokenId: nftDataDetail?.tokenId,
          nfcId: nftDataDetail?.nfcId,
        }}
        isOpen={authenticationModalOpen}
        onDismiss={toggleAuthentication}
        name={'modal_authentication'}
        reFetch={refetch}
      />
    </NFTDetailWrapper>
  )
}

const NFTDetailWrapper = styled(SectionContentWrapper)`
  margin-top: 60px;
`
const InfoNFT = styled.div``

const Div = styled.div`
  margin-block-start: 1em;
  margin-block-end: 1em;

  strong {
    color: ${({ theme }) => theme.white};
    font-weight: 400;
  }
`

const CtaBack = styled(ButtonOutline)`
  padding: 10px 16px;
  display: flex;
  align-items: center;
  font-family: 'Din Pro Bold', sans-serif;
  max-width: max-content;
  text-transform: capitalize;
  border: 1px solid ${({ theme }) => theme.secondary3};
  & > span:last-of-type {
    margin-left: 10px;
    color: ${({ theme }) => theme.primary10};
    line-height: 1.5;
  }
  &:hover {
    border-color: ${({ theme }) => theme.secondary6};
    background-color: transparent;
  }
  &:active {
    border-color: ${({ theme }) => theme.primary10};
  }
`

const Span = styled.div`
  font-size: 28px;
  line-height: 32px;
  font-weight: 500;
  color: ${({ theme }) => theme.primary5};
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 30px;
`

const DivRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -40px;
  margin-right: -40px;

  @media (max-width: 768px) {
    flex-direction: column;
    flex-wrap: nowrap;
    margin: 0 - 20px;
  }
`
const DivInfoLeft = styled.div`
  padding-left: 40px;
  padding-right: 40px;

  @media (min-width: 769px) {
    flex: 0 0 52%;
    max-width: 52%;
  }
`
const DivInfoRight = styled.div`
  padding-left: 40px;
  padding-right: 40px;

  @media (min-width: 769px) {
    flex: 0 0 48%;
    max-width: 48%;
  }

  @media (max-width: 1024px) {
    padding-left: 20px;
    padding-right: 40px;
  }

  @media (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
    margin: 0 20px;
  }
`
const DivInfoImg = styled.div`
  position: relative;
`
const DivSquare = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  height: 0;
  cursor: pointer;
  &:hover {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-image: linear-gradient(180deg, rgba(6, 24, 18, 0.9) 12.42%, rgba(6, 24, 18, 0) 100%);
      z-index: 2;
    }
    .action-hover {
      display: flex;
    }
  }
`
const DivActionHover = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  display: none;
  z-index: 5;
  .cs-favorite {
    border: 2px solid rgba(0, 255, 163, 0.16);
    height: 42px;
  }
`
const TabWrapper = styled.div`
  margin-top: 30px;
`

const TabName = styled.span`
  margin: 0;
  font-size: 30px;
`

const LoadingScreen = styled.div`
  min-height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const BoxAvailable = styled.div`
  margin-top: 50px;
  margin-bottom: 70px;
  padding: 20px;
  font-size: ${({ theme }) => theme.fontSizeText2};
  line-height: 1.5;
  border: 1px solid ${({ theme }) => theme.secondary3};
  color: ${({ theme }) => theme.secondary7};
  p {
    margin-bottom: 16px;
    &.redemption {
      margin-bottom: 0;
      display: flex;
      justify-content: space-between;
      span:last-of-type {
        color: ${({ theme }) => theme.secondary10};
      }
    }
  }

  @media (max-width: 768px) {
    margin-top: 20px;
    margin-bottom: 30px;
  }
`
const BoxImg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  bottom: 0;
  background-color: ${({ theme }) => theme.secondary1};
  img {
    width: 100%;
    height: 100%;
    display: block;
  }
`
const DivAction = styled.div`
  display: flex;
  margin-bottom: 50px;
`
const BoxFavorite = styled.div`
  display: flex;
  color: ${({ theme }) => theme.primary6};
  font-size: ${({ theme }) => theme.fontSizeText2};
  font-weight: 500;
  border: 2px solid rgba(0, 255, 163, 0.16);
  width: 94px;
  padding: 9px;
  justify-content: center;
  cursor: pointer;
  span:first-of-type {
    display: block;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    img {
      display: block;
      width: 100%;
    }
  }
  .icon-heart.active {
    color: ${({ theme }) => theme.primary6};
    fill: ${({ theme }) => theme.primary6};
  }
`
const BoxButton = styled.div``
const BoxShareMore = styled.div`
  display: flex;
  margin-left: auto;

  @media (max-width: 768px) {
    margin-top: 30px;
    margin-bottom: -15px;
  }
`
const IconBox = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  border: 2px solid ${({ theme }) => theme.secondary2};
  margin-right: 8px;
  cursor: pointer;
  img {
    display: block;
    width: 24px;
    height: 24px;
  }
`
const TitleWrapper = styled.div``
const UserCard = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`
const AvatarUser = styled.div`
  width: 24px;
  height: 24px;
  border: 1px solid ${({ theme }) => theme.primary6};
  border-radius: 20px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
`
const CustomVideo = styled.video`
  width: 24px;
  height: 24px !important;
  min-width: auto !important;
`
const Username = styled.h4`
  margin: 0 0 0 8px;
  line-height: 1.3;
  font-weight: 500;
  color: ${({ theme }) => theme.primary6};
  text-transform: capitalize;
`
const HeadingName = styled.p`
  font-size: 72px;
  line-height: 1.111111;
  color: ${({ theme }) => theme.white};
  text-transform: uppercase;
  margin: 16px 0;
  font-family: 'DIN 1451 Std', sans-serif;
`
const ProfileBox = styled.div`
  a {
    text-decoration: none;
  }
`

// const TransactionBox = styled.div`
//   margin-top: 32px;
//   padding-right: 16px;
//   max-height: 480px;
//   overflow-y: auto;
//   scroll-behavior: smooth;
//   ::-webkit-scrollbar {
//     width: 4px;
//   }
//   ::-webkit-scrollbar-track {
//     background-color: transparent;
//   }
//   ::-webkit-scrollbar-thumb {
//     background-color: ${({ theme }) => theme.secondary3};
//     border-radius: 10px;
//   }
// `
// const TransactionBoxItem = styled.div`
//   margin-bottom: 24px;
//   &:last-of-type {
//     margin-bottom: 0;
//   }
// `
const Details = styled.div``
const MoreCollection = styled.div`
  margin-top: 80px;
  padding-top: 80px;
  border-top: 1px solid ${({ theme }) => theme.secondary2};
  h1 {
    line-height: 1.25;
    color: ${({ theme }) => theme.white};
    margin: 0 0 2px 0;
    font-family: 'DIN 1451 Std', sans-serif;
    text-transform: uppercase;
  }

  @media (max-width: 768px) {
    padding-top: 40px;
    margin-top: 0;
  }
`
const BoxExpandFull = styled.div`
  width: 42px;
  height: 42px;
  border: 2px solid rgba(0, 255, 163, 0.16);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
  img {
    display: block;
    width: 24px;
    height: 24px;
  }
`
const CtaRedeem = styled(ButtonPrimary)`
  font-family: 'Din Pro Regular', sans-serif;
  padding: 10px 24px;
  width: 100%;
  font-weight: 600;
  &:not(:first-child) {
    margin-top: 10px;
  }
`

const BtnNotify = styled(ButtonSecondary)`
  margin-top: 15px;
  font-family: 'Din Pro Regular', sans-serif;
  padding: 10px 24px;
  width: 100%;
  font-weight: 400;

  img {
    margin-left: 20px;
  }
`
export default NFTDetail
