import { BigNumber } from '@ethersproject/bignumber'
import { ButtonPrimary } from 'components/Button'
import { getChainId } from 'constants/chains'
import useParticle from 'hooks/useParticle'
import React, { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { routes } from '../../../../constants/routes'
import { useClaimNFT } from '../../hooks/useClaimNFT'
import { useRedeemNFTByNFCCode } from '../../hooks/useRedeemNFTByNFCCode'
import { RedeemErrors } from '../../models/RedeemErrors'
import { ClaimNFTModal } from '../ClaimNFTModal/ClaimNFTModal'
import { WarningModal } from '../WarningModal/WarningModal'

const messages = {
  signature: 'Please proceed by signing the message on the wallet',
  register: 'Please complete the registration process before proceeding to claim.',
}

export const ClaimNFTButton = ({ className }: { className?: string }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isRegisterModalOpen, setRegisterModal] = useState(false)
  const history = useHistory()
  const { account, provider, chainId, authToken } = useParticle()
  const defaultChain = getChainId(process.env.REACT_APP_CHAIN_ID)
  const isValidChain = useMemo(() => !Boolean(chainId) || chainId === defaultChain, [chainId])

  const {
    mutateAsync: mintNFT,
    isError: isMintError,
    error: claimNFTError,
    reset: claimReset,
    isLoading: isClaimingNFT,
  } = useClaimNFT()
  const {
    data: claimedNFT,
    mutate: claimNFT,
    isSuccess,
    isError,
    reset: redeemReset,
    error: redeemNFTError,
    isLoading: isRedeemingNFT,
  } = useRedeemNFTByNFCCode({
    onSuccess: async ({ signature, tokenInformation }) => {
      await mintNFT({ signature, tokenInformation })
    },
  })

  const reset = useCallback(() => {
    claimReset()
    redeemReset()
  }, [claimReset, redeemReset])

  const handleClaimNft = useCallback(
    (code: string) => {
      if (!code.includes('_')) {
        claimNFT({ code })
      }
    },
    [claimNFT]
  )

  const handleToggleModal = useCallback(() => {
    if (provider && isValidChain && authToken) {
      setIsModalOpen(!isModalOpen)
      reset()
    } else {
      setRegisterModal(true)
    }
  }, [reset, setIsModalOpen, isModalOpen, isValidChain, provider, authToken])

  const errorMessage: string = useMemo(() => {
    if (redeemNFTError) {
      if (redeemNFTError?.response?.data?.errorCode) {
        const { errorCode } = redeemNFTError?.response?.data

        return (
          {
            [RedeemErrors.NFC_CODE_NOT_FOUND]: 'NFC not found for the given code.',
            [RedeemErrors.TOKEN_NOT_CLAIMABLE]: 'Token is not claimable',
          }[errorCode] || 'Unexpected error occurred.'
        )
      }
    }

    if (claimNFTError) {
      if (String(claimNFTError?.code) === '4001') {
        return 'Transaction signing rejected.'
      }
    }

    return 'Unexpected error occurred.'
  }, [redeemNFTError, claimNFTError])

  const handleSeeNFT = useCallback(() => {
    if (claimedNFT) {
      history.push(
        routes.NFTPreview.replace('{collectionAddress}', claimedNFT.tokenInformation.collectionAddress).replace(
          '{tokenId}',
          BigNumber.from(claimedNFT.tokenInformation.tokenId.hex).toString()
        )
      )
    }
  }, [claimedNFT, history])

  return (
    <>
      <ButtonPrimary onClick={handleToggleModal} className={className}>
        Claim NFT
      </ButtonPrimary>
      <ClaimNFTModal
        isOpen={isModalOpen}
        onClose={handleToggleModal}
        onSubmit={handleClaimNft}
        isSuccess={isSuccess}
        isError={isError || isMintError}
        errorMessage={errorMessage}
        successMessage="You have successfully claimed the NFT."
        heading="Claim the NFT"
        subheading="Scan the NFC tag in the item and enter the verification code to assign it."
        onRetry={reset}
        onSeeNFT={handleSeeNFT}
        isLoading={isRedeemingNFT || isClaimingNFT}
      />

      {isRegisterModalOpen && (
        <WarningModal
          message={!authToken && account ? messages.signature : messages.register}
          onClose={() => setRegisterModal(false)}
        />
      )}
    </>
  )
}
