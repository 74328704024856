import { Contract } from '@ethersproject/contracts'
import { formatUnits } from '@ethersproject/units'
import useParticle from 'hooks/useParticle'
import React, { useState } from 'react'
import Select from 'react-select'
import styled from 'styled-components/macro'

import Alert from '../../assets/icons/red_alert.svg'
import IconToken from '../../assets/images/svg/icon-token.svg'
import IconUSDT from '../../assets/images/svg/icon-usdt.svg'
import { SaleInformation } from '../../entities/SaleInformation'
// import useActiveWeb3React from '../../hooks/useActiveWeb3React'
import { customStylesSelect } from '../../theme'
import { ButtonPrimary } from '../Button'
import IconPortal from '../IconPortal'
import Loader from '../Loader'

const options: any = [
  { value: 'Matic', label: 'Matic', customAbbreviation: IconToken },
  { value: 'USDT', label: 'USDT', customAbbreviation: IconUSDT },
]
const formatOptionLabel = ({
  value,
  label,
  customAbbreviation,
}: {
  value: string
  label: string
  customAbbreviation: string
}) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <div style={{ marginRight: '6px', width: '24px', height: '24px' }}>
      <img src={customAbbreviation} alt={'img_pay'} style={{ width: '24px', height: '24px' }} />
    </div>
    <div>{label}</div>
  </div>
)

interface PaymentBoxProps {
  saleInformation: SaleInformation
  updateSaleInformation: () => void
  payWithMatic: boolean
  setPayWithMatic: (payWithMatic: boolean) => void
  saleAddress: string
  quantity?: number
  handleBuying: () => void
  children?: React.ReactNode
}
const PaymentBox = ({
  saleAddress,
  payWithMatic,
  setPayWithMatic,
  saleInformation,
  updateSaleInformation,
  quantity = 1,
  handleBuying,
  children,
}: PaymentBoxProps) => {
  const { account, provider } = useParticle()
  const [allowanceStatus, setAllowanceStatus] = useState('start')
  const [selectedOption] = useState<any>(() => {
    return payWithMatic
      ? {
          value: 'Matic',
          label: 'Matic',
          customAbbreviation: IconToken,
        }
      : { value: 'USDT', label: 'USDT', customAbbreviation: IconUSDT }
  })
  const handleAllowance = async () => {
    if (!account) return
    setAllowanceStatus('awaiting-confirmation')
    try {
      // const provider = library?.getSigner()
      const usdtInstance = new Contract(
        process.env.REACT_APP_USDT_ADDRESS || '',
        ['function approve(address, uint256) public returns (bool)'],
        provider?.getSigner()
      )
      const approveTx = await usdtInstance.approve(
        saleAddress,
        saleInformation.latestPriceInErc20.mul(quantity).toString()
      )
      setAllowanceStatus('confirmed')

      await approveTx.wait()
      await updateSaleInformation()
    } finally {
      setAllowanceStatus('start')
    }
  }
  return (
    <>
      <DivWalletMint>
        <DivWalletMintItem>
          <LabelName>Your balance</LabelName>
          <DivNumber>
            <SpanNumber>
              {payWithMatic
                ? formatUnits(saleInformation?.ethBalance.div(1e14).mul(1e14))
                : formatUnits(saleInformation?.erc20Balance, 6)}
            </SpanNumber>
            <IconPortal SrcImageIcon={payWithMatic ? IconToken : IconUSDT} widthIcon={'20px'} heightIcon={'20px'} />
          </DivNumber>
        </DivWalletMintItem>
        {children && <DivWalletMintItem>{children}</DivWalletMintItem>}
        <DivWalletMintItem>
          <LabelName>Payment Token</LabelName>
          <DivNumber>
            <Select
              styles={customStylesSelect}
              defaultValue={selectedOption}
              onChange={(value?: any) => {
                if (value.value === 'Matic') {
                  setPayWithMatic(true)
                } else {
                  setPayWithMatic(false)
                }
              }}
              options={options}
              classNamePrefix={'select-cs'}
              formatOptionLabel={formatOptionLabel}
              id="filter"
              name="filter"
            />
          </DivNumber>
        </DivWalletMintItem>
        <DivWalletMintItem>
          <LabelName>Total</LabelName>
          <DivNumber>
            <SpanNumber>
              {payWithMatic
                ? formatUnits(saleInformation.latestPriceInMatic.div(1e14).mul(1e14).mul(quantity))
                : formatUnits(saleInformation.latestPriceInErc20.mul(quantity), 6)}
            </SpanNumber>
            <IconPortal SrcImageIcon={payWithMatic ? IconToken : IconUSDT} widthIcon={'20px'} heightIcon={'20px'} />
          </DivNumber>
        </DivWalletMintItem>
      </DivWalletMint>
      {((!payWithMatic && saleInformation.latestPriceInErc20.mul(quantity).gt(saleInformation.erc20Balance)) ||
        (payWithMatic && saleInformation.latestPriceInMatic.mul(quantity).gt(saleInformation.ethBalance))) && (
        <Error>
          <IconPortal SrcImageIcon={Alert} widthIcon={'20px'} heightIcon={'20px'} />
          Insufficient balance
        </Error>
      )}
      <br />
      {!payWithMatic && saleInformation.latestPriceInErc20.mul(quantity).gt(saleInformation.erc20Allowance) ? (
        <ERC20AllowButton
          disabled={
            allowanceStatus !== 'start' ||
            (!payWithMatic && saleInformation.latestPriceInErc20.mul(quantity).gt(saleInformation.erc20Balance)) ||
            (payWithMatic && saleInformation.latestPriceInMatic.mul(quantity).gt(saleInformation.ethBalance))
          }
          onClick={handleAllowance}
        >
          {allowanceStatus === 'start' ? (
            'Allow USDT expense'
          ) : (
            <>
              {allowanceStatus === 'awaiting-confirmation' ? 'Waiting for confirmation' : 'Waiting for approval'}{' '}
              <Loader size={'24px'} stroke={'#151515'} />
            </>
          )}
        </ERC20AllowButton>
      ) : (
        ''
      )}
      {(payWithMatic || saleInformation.latestPriceInErc20.mul(quantity).lte(saleInformation.erc20Allowance)) && (
        <CtaStartBuying
          onClick={handleBuying}
          disabled={
            (!payWithMatic && saleInformation.latestPriceInErc20.mul(quantity).gt(saleInformation.erc20Balance)) ||
            (payWithMatic && saleInformation.latestPriceInMatic.mul(quantity).gt(saleInformation.ethBalance))
          }
        >
          CONFIRM
        </CtaStartBuying>
      )}
    </>
  )
}

export default PaymentBox

const ERC20AllowButton = styled(ButtonPrimary)`
  font-family: 'Din Pro Bold', sans-serif;
  padding: 10px 24px;
  width: 100%;
`
const CtaStartBuying = styled(ButtonPrimary)`
  font-family: 'Din Pro Bold', sans-serif;
  padding: 10px 24px;
  width: 100%;
`

export const DivNumber = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSizeText2};
  line-height: 1.5;
  gap: 5px;
  color: ${({ theme }) => theme.secondary6};
`

export const SpanNumber = styled.span`
  color: ${({ theme }) => theme.primary10};
  font-style: normal;
  margin-right: 4px;
`
const DivWalletMintItem = styled.div`
  padding: 20px 0;
  border-top: 1px solid ${({ theme }) => theme.secondary2};
  display: flex;
  align-items: center;
`
export const LabelName = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSizeText2};
  line-height: 1.5;
  text-transform: uppercase;
  color: ${({ theme }) => theme.secondary7};
`
const DivWalletMint = styled.div``

const Error = styled.div`
  color: ${({ theme }) => theme.red5};
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: end;
  gap: 8px;
`
